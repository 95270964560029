// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Notification, User, Site } = initSchema(schema);

export {
  Notification,
  User,
  Site
};