

import './App.css';

import { 
  SiteSummaryCollection,
  SiteSummary,
} from './ui-components';

import {
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";

import { Button, withAuthenticator, ThemeProvider, Theme  } from '@aws-amplify/ui-react'
import { Menu, MenuItem, View } from '@aws-amplify/ui-react';
import { Image } from '@aws-amplify/ui-react';
import { DataStore } from 'aws-amplify'
import { AccountSettings } from '@aws-amplify/ui-react';
import { Marker, Popup } from 'react-map-gl';
import Map from 'react-map-gl';

import * as React from "react";
import { useState, useEffect } from 'react'
import { API } from 'aws-amplify'

import { Site } from "./models";
import { OpenLocationCode } from 'open-location-code';


import IframeResizer from 'iframe-resizer-react'

//routing
import {
  BrowserRouter as Router,
  useNavigate,
  Routes, //replaces "Switch" used till v5
  Route,
  useParams,
} from "react-router-dom";


//add grafana controls to DIV
//maps
//alert experienc

import ReactMapGL from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';


// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;






async function fetchSiteInfo() {


    const sites = await DataStore.query(Site);




    for (let i = 0; i < sites.length; i++) {
      let site = sites[i]
      console.log("Pre set:")
      console.log(site);

      //get Lat/Lon
      let resp = {};
      //let loc = new OpenLocationCode();
      //let area = loc.decode(site.PlusCode);//area.latitudeCenter, area.longitudeCenter


      resp.elevation = site.Elevation;
      resp.depth = site.Depth;
      resp.status = site.Status;
      resp.blurb = site.Blurb;
      resp.LastSeen = site.LastSeen;
      //resp.Lat = area.latitudeCenter;
      //resp.Lon = area.longitudeCenter;

      console.log('Response creted')
      console.log(resp)

      const apiName = 'apiFrontEnd';
      const path = '/siteSummary';
      
      const myInit = {
      headers: {}, // OPTIONAL
      //response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        siteID: site.PlusCode // OPTIONAL
      }};


    
      try{
        let response = await API.get(apiName, path, myInit);
  
        resp.elevation = response.elevation;
        resp.depth = response.depth;
        resp.status = response.status
        resp.blurb = response.blurb
        resp.LastSeen = response.LastSeen

        //await DataStore.save(site);

      }catch{
        console.log("ERROR with API");
      }


      await DataStore.save(
        Site.copyOf(site, updated => {
        updated.Elevation = resp.elevation
        updated.Depth = resp.depth
        updated.Status = resp.status
        updated.Blurb = resp.blurb
        updated.LastSeen = resp.LastSeen
        //updated.Lat = resp.latitudeCenter
        //updated.Lon = resp.longitudeCenter
      }));


      } 





}


//https://www.copycat.dev/blog/react-js-navigation/
function App ({ signOut }) {

  //fetch updates for all sites and update datastore
  //const itemsDataStore = useDataStoreBinding({
  //  type: "collection",
  //  model: Site,
  ///}).items;


  fetchSiteInfo();


  return (

  <>   
      <Router>
        <Header signOut={ signOut } />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dash/:siteId" element={<SiteDashboard />} />
            <Route path="/account" element={<User />} />
            <Route path="/map" element={<Mapview />} />
            <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </>   

  );
}

function Header({ signOut }){
  let navigate = useNavigate();
  //https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/parent_page/options.md
  return (
      
    <>   
    
    <div className='rowC'>

      <Image
        alt="Hyfi Logo"
        src="/HyfiLogo.png"
        objectFit="initial"
        objectPosition="50% 50%"
        backgroundColor="initial"
        height="4%"
        width="4%"
        opacity="100%"
        onClick={() => { navigate('/')}}
      />

      <Menu >
      <MenuItem onClick={() => navigate('/account')}>My Account</MenuItem>
      <MenuItem onClick={async () => {DataStore.clear(); signOut()}}>Sign out</MenuItem>
      </Menu>

    </div>

    </>   

    )
    
}

//https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/parent_page/options.md
//https://play.hyfi.io/d/o-jOve0nk/hyfi-site-view?orgId=2&var-site_code=86HWC9QQ%2B83HF&kiosk&theme=light
function SiteDashboard(){
  let params = useParams();
  let dashLoc = "https://play.hyfi.io/d/o-jOve0nk/hyfi-site-view?var-site_code="+ params.siteId + "&kiosk&theme=light"
  dashLoc = dashLoc.replace("+", "%2B");
  //alert(dashLoc);

  return (
      
    <>   
     <IframeResizer
  src={dashLoc}
  style={{ width: '1px', 
          minWidth: '100%',  
          heightCalculationMethod: 'bodyScroll', 
          scrolling:'yes', 
          autoResize:'true', 
          minHeight:'100vh',
          border:'none'}
          }
/>
</>   

    )
    
}




function Home(){
  let navigate = useNavigate();
  //https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/parent_page/options.md

  return (
    <>   
  <div className='container'>
    <ThemeProvider theme={mainButtonTheme} colorMode="light">
          <div class="modal"><Button variation="primary" onClick={() => navigate('/map')}>Show Map</Button></div>
    </ThemeProvider>
          
          <SiteSummaryCollection overrideItems={({ item, idx }) => {
            return {
              overrides: {
                "SiteSummary": {
                  //as: 'button',
                  onClick: () => {
                    navigate('/dash/' + item?.PlusCode)
                  },
                  style:{cursor:"pointer"}
                },
              }
            }
          }}
        />
      </div>
      </>   
    )
    
    
}


function User() {
  const handleSuccessChange = () => {
    alert('Your password was successfully changed.')
  }

  const handleSuccessDelete = () => {
    alert('Your account has been deleted. Pleas sign up again if you want to use Hyfi. ')
  }
  
  return (
    <div className='container'>
      <h1> Change Your Password </h1>
      <AccountSettings.ChangePassword onSuccess={handleSuccessChange}/>
      <h1> Danger Zone: Delete your account </h1>
      <AccountSettings.DeleteUser onSuccess={handleSuccessDelete} />
    </div>
  );
}

function MarkerWithPopup({ site }) {
  const [showPopup, setShowPopup] = useState(false);

  let navigate = useNavigate();
  
  let loc = new OpenLocationCode();
  let area = loc.decode(site.PlusCode);//area.latitudeCenter, area.longitudeCenter
  
  
  const latitude = area.latitudeCenter;
  const longitude = area.longitudeCenter;


  const handleMarkerClick = ({ originalEvent }) => {
    originalEvent.stopPropagation();
    setShowPopup(true);
  };

  let val = site.Elevation;
  val = val.replace(' ft','');

  return (
    <>

      <Marker
        latitude={latitude}
        longitude={longitude}
        onClick={handleMarkerClick}>
            <ThemeProvider theme={mapButtonTheme} colorMode="light">
              <Button variation="primary" size="small">{val}</Button>
            </ThemeProvider>
      </Marker>
      {showPopup && (
        <Popup
          latitude={latitude}
          longitude={longitude}
          offset={{ bottom: [0, -40] }}
          onClose={() => setShowPopup(false)}
          maxWidth= "400px"
          closeButton = {false}
          style = {{backgroundColor:'clear'}}
        >
       
              <SiteSummary  site={site} height="100%" width="100%" 
              overrides={{
                "SiteSummary": {
                  onClick: () => {
                    navigate('/dash/' + site?.PlusCode)
                  },
                  border:"0px SOLID rgba(0,0,0,1)",
                  //backgroundColor:'rgba(0,0,0,1)',
                  borderRadius:"10px",
                  style:{cursor:"pointer"}
                }
              }}></SiteSummary>
              


        </Popup>
      )}
    </>
  );
}

export function Mapview() {

  const [viewState, setViewState] = React.useState({
    longitude: -100,
    latitude: 40,
    zoom: 3.5
  });

  let navigate = useNavigate();
 
  const sites = useDataStoreBinding({
  type: "collection",
  model: Site,
  }).items;

  console.log("Query in maps")
  console.log(sites)

  

  return (

    
    <>
      <Map reuseMaps
        {...viewState}
        onMove={evt => setViewState(evt.viewState)}
        style={{ width: '100vw', height: '100vh' }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapboxAccessToken="pk.eyJ1IjoiYmtlcmtleiIsImEiOiJjbGNtbHc1ajUwdTd1M3BwOHZkeWV3bGQxIn0.mjF8LTF0_QLIoGInlFmrRA">;

      {sites.map((value, index) => {

        return <MarkerWithPopup site={value} />;

      })}
    </Map>
    <ThemeProvider theme={mainButtonTheme} colorMode="light">
      <div class="modal"><Button variation="primary" onClick={() => navigate('/')}>Show List</Button></div>
    </ThemeProvider>
    </>



  );
}

//https://play.hyfi.io/d/do9H1stVk/hyfi-single-view-air?orgId=2&var-site_code=86HWC9MV%2B8H9X&viewPanel=26



const mainButtonTheme: Theme = {
  name: 'main-button-theme',
  tokens: {
    colors: {
      border: {
        // this will affect the default button's border color
        primary: { value: 'black' },
      },
    },
    components: {
      button: {
        // this will affect the font weight of all button variants
        fontWeight: { value: '{fontWeights.extrabold}' },
        // style the primary variation
        primary: {
          backgroundColor: { value: 'black' },
          _hover: {
            backgroundColor: { value: 'gray' },
          },
          _focus: {
            backgroundColor: { value: 'gray' },
          },
          _active: {
            backgroundColor: { value: 'gray' },
          },
        },
      },
    },
  },
};

const mapButtonTheme: Theme = {
  name: 'map-button-theme',
  tokens: {
    colors: {
      border: {
        // this will affect the default button's border color
        primary: { value: 'black' },
      },
    },
    components: {
      button: {
        // this will affect the font weight of all button variants
        fontWeight: { value: '{fontWeights.extrabold}' },
        // style the primary variation
        primary: {
          backgroundColor: { value: 'rgba(188,71,198,1)' },
          _hover: {
            backgroundColor: { value: 'rgba(200,150,198,1)' },
          },
          _focus: {
            backgroundColor: { value: 'gray' },
          },
          _active: {
            backgroundColor: { value: 'gray' },
          },
        },
      },
    },
  },
};


export default withAuthenticator(App);


