

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// src/index.js
import { Amplify } from 'aws-amplify'
import config from './aws-exports'

import { ThemeProvider } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

Amplify.configure(config)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider colorMode='dark'>
      <App />
    </ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


//https://play.hyfi.io/d/o-jOve0nk/hyfi-site-view?orgId=2&var-site_code=86HWC9QQ%2B83HF&kiosk=tv